import { useState } from 'react'
import emailjs, { init } from '@emailjs/browser';

init(process.env.REACT_APP_EMAILJS_USER_ID);

const initialState = {
  // name: '',
  email: '',
  // message: '',
	honeypot: '',
}


// TODO: Clean up unused fields from contact
export const Contact = (props) => {
	const [{
		// name,
		email,
		// message,
		honeypot
	}, setState] = useState(initialState);
	
  const handleChange = (event) => {
    const { name, value } = event.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (event) => {
    event.preventDefault();

		if(honeypot) {
			return false;
		}

		const templateParams = {
			// name,
			email,
			// message,
		};

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
				templateParams
      )
      .then(
        (response) => {
          console.log('Success', response.status, response.text);
          clearState();
        },
        (error) => {
          console.error('Failed', error.text);
        }
      )
  }

  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get Early Access</h2>
                <p>
                  Enter your email below for pre-launch access to our platform
                </p>
              </div>
              <form name='sentMessage' validate="true" onSubmit={handleSubmit}>
                <div className='row'>
                  {/* <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
												value={name}
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div> */}
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
												value={email}
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                {/* <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
										value={message}
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div> */}
								<fieldset className="honeypot-field">
									<label htmlFor="honeypot">
										To help avoid spam, utilize a Honeypot technique with a{' '}
										hidden text field; must be empty to submit the form!{' '}
										Otherwise, we assume the user is a spam bot.
									</label>
									<input
										id="honeypot"
										type="text"
										name="honeypot"
										value={honeypot}
										onChange={handleChange}
									/>
								</fieldset>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Join the movement
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
						<h3>Contact Info</h3>
            {props.data && props.data.address && (
							<div className='contact-item'>
								<p>
									<span>
										<i className='fa fa-map-marker'></i> Address
									</span>
									{props.data.address}
								</p>
							</div>
						)}
            {props.data && props.data.phone && (
							<div className='contact-item'>
								<p>
									<span>
										<i className='fa fa-phone'></i> Phone
									</span>{' '}
									{props.data ? props.data.phone : 'loading'}
								</p>
							</div>
						)}
           {props.data && props.data.email && (
						<div className='contact-item'>
								<p>
									<span>
										<i className='fa fa-envelope-o'></i> Email
									</span>{' '}
									<a href="mailto:general@bazo.co">{props.data.email}</a>
								</p>
							</div>
						)}
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
